import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import { Slide, Image } from './LogosSlider.styled';

const LogosSlider = ({ logos }) => {
  const config = {
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...config}>
      {logos.map(logo => (
        <Slide key={logo.id}>
          <Image image={getImage(logo.image)} alt={logo.title} />
        </Slide>
      ))}
    </Slider>
  );
};

export default LogosSlider;
