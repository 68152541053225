import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Slide = styled.div`
  padding: 0 15px;
  text-align: center;
  outline: none;
`;

export const Image = styled(GatsbyImage)`
  display: block !important;
  max-width: 100%;
  margin: 0 auto;

  img {
    margin: 0;
    object-fit: contain !important;
  }
`;
