import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 15px;
  padding: 20px;
  transition: box-shadow .3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }

  @media(min-width: 576px) {
    margin: 0 0 30px;
    padding: 20px 30px;
  }
`;

export const Image = styled(GatsbyImage)`
  display: block !important;
  min-width: 100px;
  margin: 0 20px 0 0;
`;

export const Title = styled.h3`
  margin: 0 0 1rem;
  color: #d72226;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
`;

export const Description = styled.p`
  margin: 0;
  font-size: .875rem;
  line-height: 1.25;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
