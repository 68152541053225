import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import slugify from 'slugify';
import { Wrapper, Image, Title, Description, StyledLink } from './OfferBlock.styled';

const OfferBlock = ({offer}) => {
  return (
    <Wrapper>
      <Image image={getImage(offer.image)} alt={offer.title} />
      <div>
        <Title>{offer.title}</Title>
        <Description>{offer.description}</Description>
      </div>
      <StyledLink to={`/oferta/${slugify(offer.title, { lower: true })}/katalog`} />
    </Wrapper>
  );
};

export default OfferBlock;
