import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Slide = styled.div`
  display: block !important;
  position: relative;
  height: 300px;
  background: #000000;
  overflow: hidden;
  outline: none;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  &.active {
    h1 {
      opacity: 1;
      transform: translateX(0);

      span {
        opacity: 1;
        transform: translateX(0);
      }
    }

    h2 {
      opacity: 1;
      transform: translateX(0);
    }

    .buttons {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h1, h2 {
    color: #FFFFFF;
    text-transform: uppercase;
  }

  h1 {
    font-size: 2rem;
    line-height: .75;
    word-wrap: normal;
    opacity: 0;
    transform: translateX(200px);
    transition: opacity .5s, transform .5s;

    span {
      display: block;
      color: #d72226;
      font-weight: 400;
      opacity: 0;
      transform: translateX(200px);
      transition: opacity .5s .15s, transform .5s .15s;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 400;
    opacity: 0;
    transform: translateX(200px);
    transition: opacity .5s .3s, transform .5s .3s;
  }

  .buttons {
    margin: -10px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity .5s .5s, transform .5s .5s;

    button, a {
      margin: 10px;
    }
  }

  @media (min-width: 992px) {
    h1 {
      font-size: 2.5rem;
      margin: 0 0 1.25rem;
    }

    h2 {
      font-size: 1.25rem;
      margin: 0 0 2.5rem;
    }
  }
`;

export const Image = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  opacity: .5;
`;
